/**
 * Class to help with the currency.
 */
export class CurrencyHelper {
    /**
     * Format the value as USD with no decimal places.
     * @param value The value to format.
     * @returns The formatted valued, or 0 if the value is not formattable.
     */
    public static formatUsd(value: number): string {
        if (value === undefined || isNaN(value)) {
            return `$ 0`;
        } else {
            return `$ ${Math.round(value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
        }
    }

    /**
     * Format the value as USD in raw format.
     * @param value The value to format.
     * @returns The formatted valued, or 0 if the value is not formattable.
     */
    public static formatUsdRaw(value: string): string {
        const v = parseFloat(value);
        if (v === undefined || isNaN(v)) {
            return `0`;
        } else {
            return v.toFixed(2);
        }
    }
}
