import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import "./header.css";
import logoSvg from "./logo.svg";

/**
 * Component to display the header of the page.
 */
class Header extends Component {
    /**
     * Render the component.
     * @returns The component content.
     */
    public render(): React.ReactNode {
        return (
            <Grid container spacing={3} alignItems="center">
                <Grid item>
                    <Grid container spacing={3} alignItems="center" className="logo-container">
                        <Grid item>
                            <img src={logoSvg} className="logo" alt="IOTA Ecosystem" />
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" color="secondary">
                                IOTA Ecosystem Development Fund
                            </Typography>
                            <Typography variant="subtitle1" color="secondary">
                                Grant/Donation Tracker
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {this.props.children && (
                    <Grid item className="nav-container">
                        {this.props.children}
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default Header;
