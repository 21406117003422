import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArchiveIcon from "@material-ui/icons/Archive";
import AssignmentIcon from "@material-ui/icons/Assignment";
import React, { Component } from "react";
import "./audit.css";
import AuditCard from "./auditCard";
import { DataCacheHelper } from "./helpers/dataCacheHelper";
import { DateHelper } from "./helpers/dateHelper";
import { TextHelper } from "./helpers/textHelper";
import { IAuditData } from "./models/IAuditData";
import { IAuditOperation } from "./models/IAuditOperation";
import { IDataTableIndex } from "./models/IDataTableIndex";
import { IEdfGrantTrackerConfiguration } from "./models/IEdfGrantTrackerConfiguration";
import { IGrantDonation } from "./models/IGrantDonation";
import { IStatus } from "./models/IStatus";
import StatusCard from "./statusCard";

/**
 * Component to display a list of all grants/donations.
 */
class Audit extends Component<
    {
        configuration: IEdfGrantTrackerConfiguration;
    },
    {
        status: IStatus;
        indexes: { hash: string; index: IDataTableIndex; auditOperation?: IAuditOperation }[];
    }> {

    private readonly _grantDonations: { [id: string]: IGrantDonation };

    constructor(
        props: { configuration: IEdfGrantTrackerConfiguration },
        context: {}
    ) {
        super(props, context);
        this.state = {
            status: {
                showProgress: true,
                message: ""
            },
            indexes: []
        };
        this._grantDonations = {};
    }

    /**
     * The component mounted.
     */
    public async componentDidMount(): Promise<void> {
        this.setState({
            status: {
                showProgress: true,
                message: "Loading current index"
            }
        });

        let message = "";
        try {
            const dataTableConfiguration = await DataCacheHelper.loadConfig(this.props.configuration);

            if (dataTableConfiguration &&
                dataTableConfiguration[this.props.configuration.edfGrantTrackerTableName]) {

                if (dataTableConfiguration[this.props.configuration.edfGrantTrackerTableName].indexBundleHash) {
                    await this.loadIndex(dataTableConfiguration[this.props.configuration.edfGrantTrackerTableName].indexBundleHash);
                }

                if (this.state.indexes.length > 0) {
                    this.calculateOperations();
                } else {
                    message = `There is no audit data to display.`;
                }
            } else {
                message = `Viewing audit failed, please refresh the page to try again.
If this page continues to fail try again later.`;
            }
        } catch (err) {
            message = `Viewing audit failed, please refresh the page to try again.
If this page continues to fail try again later.`;
        }

        this.setState({
            status: {
                showProgress: false,
                message
            }
        });
    }

    /**
     * Render the component.
     * @returns The component content.
     */
    public render(): React.ReactNode {
        return (
            <Grid container spacing={3}>
                <StatusCard status={this.state.status} />

                {this.state.indexes.length > 0 && !this.state.status.showProgress && (
                    <Grid item xs={12}>
                        <Card className="audit-card">
                            <CardContent>
                                <Grid container spacing={1} direction="column">
                                    <Grid item>
                                        <Grid container alignItems="center" className="header-nowrap">
                                            <Grid item>
                                                <Button
                                                    onClick={async e => this.exportData()}
                                                    size="small"
                                                    color="primary"
                                                    variant="contained"
                                                    className="main-button">
                                                    <ArchiveIcon />&nbsp;
                                                    Export Data
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body1">
                                                    Export all of the current and historic tracker data as JSON.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {this.props.configuration.rsaPublicKey && (
                                        <Grid item>
                                            <Grid container alignItems="center" className="header-nowrap">
                                                <Grid item>
                                                    <Button
                                                        onClick={async e => this.exportPublicKey()}
                                                        size="small"
                                                        color="primary"
                                                        variant="contained"
                                                        className="main-button">
                                                        <AssignmentIcon />&nbsp;&nbsp;&nbsp;
                                                        Public Key&nbsp;&nbsp;
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1">
                                                        Download the public RSA key.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {this.props.configuration.auditRepo && (
                                        <Grid item>
                                            <Grid container alignItems="center" className="header-nowrap">
                                                <Grid item>
                                                    <Button
                                                        onClick={async e => this.openGitHub()}
                                                        size="small"
                                                        color="primary"
                                                        variant="contained"
                                                        className="main-button">
                                                        <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1.5em" width="1.5em" viewBox="0 0 40 40">
                                                            <g>
                                                                <path d="m20 0c-11 0-20 9-20 20 0 8.8 5.7 16.3 13.7 19 1 0.2 1.3-0.5 1.3-1 0-0.5 0-2 0-3.7-5.5
                                                                1.2-6.7-2.4-6.7-2.4-0.9-2.3-2.2-2.9-2.2-2.9-1.9-1.2 0.1-1.2 0.1-1.2 2 0.1 3.1 2.1 3.1 2.1 1.7 3
                                                                    4.6 2.1 5.8 1.6 0.2-1.3 0.7-2.2 1.3-2.7-4.5-0.5-9.2-2.2-9.2-9.8 0-2.2 0.8-4 2.1-5.4-0.2-0.5-0.9-2.6
                                                                    0.2-5.3 0 0 1.7-0.5 5.5 2 1.6-0.4 3.3-0.6 5-0.6 1.7 0 3.4 0.2 5 0.7 3.8-2.6 5.5-2.1 5.5-2.1 1.1 2.8
                                                                    0.4 4.8 0.2 5.3 1.3 1.4 2.1 3.2 2.1 5.4 0 7.6-4.7 9.3-9.2 9.8 0.7 0.6 1.4 1.9 1.4 3.7 0 2.7 0 4.9
                                                                    0 5.5 0 0.6 0.3 1.2 1.3 1 8-2.7 13.7-10.2 13.7-19 0-11-9-20-20-20z"/>
                                                            </g>
                                                        </svg>
                                                        &nbsp;&nbsp;GitHub Repo
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1">
                                                        To see how to validate the signatures in the exported data take a look at the code on the GitHub repo.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {this.state.indexes.map(index => (
                            <Grid key={index.hash} item xs={12} md={6}>
                                <AuditCard
                                    configuration={this.props.configuration}
                                    item={index}
                                    grantDonation1={index.auditOperation ? this._grantDonations[index.auditOperation.hash1] : undefined}
                                    grantDonation2={index.auditOperation ? this._grantDonations[index.auditOperation.hash2] : undefined}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    private async loadIndex(indexHash: string): Promise<void> {
        this.setState({
            status: {
                showProgress: true,
                message: `Loading index ${indexHash}`
            }
        });

        const index = await DataCacheHelper.loadIndex(
            this.props.configuration,
            this.props.configuration.edfGrantTrackerTableName,
            indexHash);

        if (index) {
            if (index.bundles) {
                for (let i = 0; i < index.bundles.length; i++) {
                    if (!this._grantDonations[index.bundles[i]]) {
                        this._grantDonations[index.bundles[i]] = await DataCacheHelper.loadItem<IGrantDonation>(
                            this.props.configuration,
                            this.props.configuration.edfGrantTrackerTableName,
                            index.bundles[i]);
                    }
                }
            }

            const newIndexes = this.state.indexes;
            newIndexes.push({ hash: indexHash, index });
            this.setState({ indexes: newIndexes });

            if (index.lastIdx) {
                await this.loadIndex(index.lastIdx);
            }
        }
    }

    private calculateOperations(): void {
        const indexes = this.state.indexes.slice();
        for (let i = 0; i < indexes.length; i++) {
            indexes[i].auditOperation = this.calculateOperation(
                indexes[i].index,
                i < indexes.length - 1 ? indexes[i + 1].index : undefined);
        }
        this.setState({ indexes });
    }

    private calculateOperation(index1: IDataTableIndex, index2: IDataTableIndex): IAuditOperation {
        let missing1;
        let missing2;
        if (index2) {
            for (let i = 0; i < index1.bundles.length && !missing1; i++) {
                if (index2.bundles.indexOf(index1.bundles[i]) < 0) {
                    missing1 = index1.bundles[i];
                }
            }

            for (let i = 0; i < index2.bundles.length && !missing2; i++) {
                if (index1.bundles.indexOf(index2.bundles[i]) < 0) {
                    missing2 = index2.bundles[i];
                }
            }
        } else {
            missing1 = index1.bundles[0];
        }

        if (missing1 && missing2) {
            return {
                operation: "UPDATE",
                hash1: missing2,
                hash2: missing1
            };
        } else if (missing2) {
            return {
                operation: "DELETE",
                hash1: missing2
            };
        } else {
            return {
                operation: "ADD",
                hash1: missing1
            };
        }
    }

    private async exportData(): Promise<void> {
        this.setState({
            status: {
                showProgress: true,
                message: "The data is being exported.\nPlease wait..."
            }
        });

        try {
            const data: IAuditData = {
                storageRoot: `${this.props.configuration.storageRoot}${this.props.configuration.projectsPath}/`,
                indexes: this.state.indexes.map(ih => ({ index: ih.index, auditOperation: ih.auditOperation })),
                grantDonations: this._grantDonations
            };

            const json = JSON.stringify(data, undefined, "\t");

            const asciiEncoded = TextHelper.encodeNonASCII(json);

            this.saveFile(asciiEncoded, "application/json", `audit-data-${DateHelper.formatFileStamp(Date.now())}.json`);
        } catch (err) {
        }
        this.setState({ status: { showProgress: false, message: "" } });
    }

    private openGitHub(): void {
        window.open(this.props.configuration.auditRepo, "_blank");
    }

    private exportPublicKey(): void {
        this.saveFile(this.props.configuration.rsaPublicKey, "plain/text", `public.key`);
    }

    private saveFile(data: string, mime: string, filename: string): void {
        const blob = new Blob([data], { type: mime });
        if (typeof window.navigator.msSaveBlob !== "undefined") {
            // IE workaround for "HTML7007: One or more blob URLs were
            // revoked by closing the blob for which they were created.
            // These URLs will no longer resolve as the data backing
            // the URL has been freed."
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const blobURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement("a");
            tempLink.style.display = "none";
            tempLink.href = blobURL;
            tempLink.setAttribute("download", filename);

            // Safari thinks _blank anchor are pop ups. We only want to set _blank
            // target if the browser does not support the HTML5 download attribute.
            // This allows you to download files in desktop safari if pop up blocking
            // is enabled.
            if (typeof tempLink.download === "undefined") {
                tempLink.setAttribute("target", "_blank");
            }

            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }
    }
}

export default Audit;
