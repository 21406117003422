import { IEdfGrantTrackerConfiguration } from "../models/IEdfGrantTrackerConfiguration";
import { AmazonS3Client } from "../network/amazonS3Client";
import { LocalStorageClient } from "../network/localStorageClient";
import { NetworkClient } from "../network/networkClient";
import { TangleCacheClient } from "../network/tangleCacheClient";

/**
 * Component to help with configuration loading.
 */
export class ConfigHelper {
    /**
     * Load the configuration from the url.
     * @param configUrl The url to load the config from.
     * @returns The configuration.
     */
    public static async loadConfig(configUrl: string): Promise<IEdfGrantTrackerConfiguration> {
        return fetch(configUrl)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(`Could not load configuration file ${configUrl}`);
                }
            });
    }

    /**
     * Create a client to communicate with local storage.
     * @returns The client instance.
     */
    public static createLocalStorageClient(): LocalStorageClient {
        return new LocalStorageClient();
    }

    /**
     * Create a client to communicate with the amazon S3.
     * @param configuration The configuration to use for creating the client.
     * @returns The client instance.
     */
    public static createAmazonS3Client(configuration: IEdfGrantTrackerConfiguration): AmazonS3Client {
        const networkClient = new NetworkClient();
        return new AmazonS3Client(networkClient, configuration.storageRoot);
    }

    /**
     * Create a client to communicate with the tangle cache.
     * @param configuration The configuration to use for creating the client.
     * @returns The client instance.
     */
    public static createTangleCacheClient(configuration: IEdfGrantTrackerConfiguration): TangleCacheClient {
        const amazonS3Client = ConfigHelper.createAmazonS3Client(configuration);
        return new TangleCacheClient(amazonS3Client, configuration.tangleCachePath);
    }
}
