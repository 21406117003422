import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TimelineIcon from "@material-ui/icons/Timeline";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import React, { Component } from "react";
import { Link, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import Audit from "./audit";
import Header from "./header";
import { DataCacheHelper } from "./helpers/dataCacheHelper";
import { IEdfGrantTrackerConfiguration } from "./models/IEdfGrantTrackerConfiguration";
import { IStatus } from "./models/IStatus";
import { NetworkClient } from "./network/networkClient";
import "./root.css";
import StatusCard from "./statusCard";
import View from "./view";
import "./view.css";

/**
 * The top level theme to use for the display.
 */
const theme = createMuiTheme({
    typography: {
        fontFamily:
            "Nunito, -apple-system,system-ui,BlinkMacSystemFont," +
            "\"Segoe UI\",\"Helvetica Neue\",Arial,sans-serif"
    },
    palette: {
        primary: {
            light: "#59dcd3",
            main: "#00aaa2",
            dark: "#007a73",
            contrastText: "#fff"
        },
        secondary: {
            light: "#f5ffff",
            main: "#ffffff",
            dark: "#91b0b0",
            contrastText: "#000"
        }
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: 3
            }
        }
    }
});

/**
 * The root component of the grant/donation tracker.
 */
class Root extends Component<RouteComponentProps, {
    status: IStatus;
    configuration: IEdfGrantTrackerConfiguration;
}> {
    constructor(props: RouteComponentProps, context: {}) {
        super(props, context);

        this.state = {
            status: {
                showProgress: true,
                message: ""
            },
            configuration: undefined
        };
    }

    public async componentWillMount(): Promise<void> {
        this.setState({ status: { showProgress: true, message: "Initializing, please wait..." } });

        let failed = true;
        try {
            const networkClient = new NetworkClient();
            const configuration = await networkClient.requestJson<void, IEdfGrantTrackerConfiguration>(
                "/config/config.json", "GET");

            const tableConfig = await DataCacheHelper.loadConfig(configuration);
            DataCacheHelper.checkLocalStorageVersion();

            if (tableConfig) {
                failed = false;
                this.setState({
                    configuration
                });
            }

        } catch (err) {
        }

        this.setState({
            status: {
                showProgress: false,
                message: failed ? `Initialization failed, please refresh the page to try again.

If the problem persists please try again later.` : ""
            }
        });

    }

    /**
     * Render the component.
     * @returns The component content.
     */
    public render(): React.ReactNode {
        return (
            <MuiThemeProvider theme={theme}>
                <Grid container direction="column" alignItems="stretch" justify="space-between">
                    <Grid item xs={12}>
                        <Grid container spacing={1} direction="column" alignItems="stretch">
                            <Grid item xs={12}>
                                <Header>
                                    {!this.state.status.showProgress && !this.state.status.message && (
                                        <React.Fragment>
                                            <Button color="secondary" variant="contained"
                                                component={({ ...props }: any) =>
                                                    <Link to="/" {...props} />}
                                            ><ViewModuleIcon/>&nbsp;Tracker</Button>
                                            <Button color="secondary" variant="contained"
                                                component={({ ...props }: any) =>
                                                    <Link to="/audit" {...props} />}
                                            ><TimelineIcon/>&nbsp;Audit</Button>
                                        </React.Fragment>
                                    )}
                                </Header>
                            </Grid>

                            <StatusCard status={this.state.status} />

                            {!this.state.status.showProgress && !this.state.status.message && (
                                <Grid item xs={12} className="content">
                                    <Switch>
                                        <Route exact path="/"
                                            render={() =>
                                                <View configuration={this.state.configuration} />} />
                                        <Route exact path="/audit"
                                            render={() =>
                                                <Audit configuration={this.state.configuration} />} />
                                    </Switch>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        );
    }
}

export default withRouter(Root);
