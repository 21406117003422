import { IDataTableConfigCollection } from "../models/IDataTableConfigCollection";
import { IDataTableIndex } from "../models/IDataTableIndex";
import { IEdfGrantTrackerConfiguration } from "../models/IEdfGrantTrackerConfiguration";
import { ConfigHelper } from "./configHelper";

/**
 * Class to help cache data retieved from database.
 */
export class DataCacheHelper {
    /* @internal */
    private static readonly LOCAL_STORAGE_VERSION: string = "2.0.1";

    /* @internal */
    private static dataTableConfigCollection: IDataTableConfigCollection;

    /**
     * Load the configuration for the data cache.
     * @param configuration The configuration for retrieving the data.
     * @returns The data table configuration.
     */
    public static async loadConfig(configuration: IEdfGrantTrackerConfiguration): Promise<IDataTableConfigCollection> {
        if (!DataCacheHelper.dataTableConfigCollection) {
            const localStorageClient = ConfigHelper.createLocalStorageClient();
            const localStorageConfig = localStorageClient.load<IDataTableConfigCollection>(configuration.configName);

            try {
                // Load from amazon
                const amazonS3Client = ConfigHelper.createAmazonS3Client(configuration);
                DataCacheHelper.dataTableConfigCollection = await amazonS3Client.getItem<IDataTableConfigCollection>(`${configuration.configName}.json`);

                if (DataCacheHelper.dataTableConfigCollection && localStorageConfig) {
                    // If the data or table addresses locally are differnt to those in the real config
                    // we should clear local storage
                    const tables = Object.keys(DataCacheHelper.dataTableConfigCollection);
                    tables.forEach(table => {
                        if (DataCacheHelper.dataTableConfigCollection[table].indexAddress !== localStorageConfig[table].indexAddress ||
                            DataCacheHelper.dataTableConfigCollection[table].dataAddress !== localStorageConfig[table].dataAddress) {
                            localStorageClient.clear(table);
                        }
                    });
                }
            } catch (err) {
                // Try loading locally instead
            }

            if (DataCacheHelper.dataTableConfigCollection) {
                // We managed to load from amazon so store in local storage as a backup
                localStorageClient.save<IDataTableConfigCollection>(configuration.configName, DataCacheHelper.dataTableConfigCollection);
            } else {
                // We failed to load from amazon so see just use the local cached version
                DataCacheHelper.dataTableConfigCollection = localStorageConfig;
            }
        }

        return DataCacheHelper.dataTableConfigCollection;
    }

    /**
     * Load the current data for the specified table.
     * @param configuration The configuration to use for loading.
     * @param tableName The table to load the data for.
     * @returns The current data for the table.
     */
    public static async loadCurrent<T>(configuration: IEdfGrantTrackerConfiguration, tableName: string): Promise<T[]> {
        const allData: T[] = [];

        const index = await DataCacheHelper.loadIndex(configuration, tableName, undefined);

        if (index && index.bundles) {
            for (let i = 0; i < index.bundles.length; i++) {
                const item = await DataCacheHelper.loadItem<T>(configuration, tableName, index.bundles[i]);
                if (item) {
                    allData.push(item);
                }
            }
        }

        return allData;
    }

    /**
     * Load the index for the specified table.
     * @param configuration The configuration to use for loading.
     * @param tableName The table to load the index for.
     * @param indexHash The bundle hash of the index to load, if undefined will load current index.
     * @returns The table index for the specified hash.
     */
    public static async loadIndex(configuration: IEdfGrantTrackerConfiguration, tableName: string, indexHash: string): Promise<IDataTableIndex> {
        let currentIndex: IDataTableIndex;
        let loadIndexHash = indexHash;

        // No index hash provided so lookup the current one for the specified table.
        if (!loadIndexHash) {
            const dataTableConfiguration = await DataCacheHelper.loadConfig(configuration);

            if (dataTableConfiguration) {
                if (dataTableConfiguration[tableName]) {
                    loadIndexHash = dataTableConfiguration[tableName].indexBundleHash;
                }
            }
        }

        if (loadIndexHash) {
            // First try and load the index from local storage
            const localStorageClient = ConfigHelper.createLocalStorageClient();
            currentIndex = localStorageClient.load<IDataTableIndex>(`${tableName}/${loadIndexHash}`);

            if (!currentIndex) {
                // No index in local storage, so try from the tangle cache
                try {
                    const tangleCacheClient = ConfigHelper.createTangleCacheClient(configuration);
                    currentIndex = await tangleCacheClient.loadBundle<IDataTableIndex>(loadIndexHash);
                } catch (err) {
                    // Error getting from cache so try getting from tangle
                }

                if (currentIndex) {
                    // We now have an index so store it in local storage for future fast retrieval
                    localStorageClient.save(`${tableName}/${loadIndexHash}`, currentIndex);
                }
            }
        }

        return currentIndex;
    }

    /**
     * Load an item from the data cache.
     * @param configuration The configuration to use for loading.
     * @param tableName The table to load the item from.
     * @param itemHash The hash of the item to load.
     * @returns The loaded item.
     */
    public static async loadItem<T>(configuration: IEdfGrantTrackerConfiguration, tableName: string, itemHash: string): Promise<T> {
        // Try local storage first
        const localStorageClient = ConfigHelper.createLocalStorageClient();
        let item: T = localStorageClient.load<T>(`${tableName}/${itemHash}`);

        if (!item) {
            // No item so try the tangle cache
            try {
                const tangleCacheClient = ConfigHelper.createTangleCacheClient(configuration);
                item = await tangleCacheClient.loadBundle<T>(itemHash);
            } catch (err) {
                // Cant load the item
            }

            if (item) {
                // We now have the item so store in local storage for fast retrieval later.
                localStorageClient.save(`${tableName}/${itemHash}`, item);
            }
        }

        return item;
    }

    /**
     * Check the local storage version against current, if it is different then reset the storage.
     */
    public static checkLocalStorageVersion(): void {
        const localStorageClient = ConfigHelper.createLocalStorageClient();

        const currentVersion = localStorageClient.load<{ version: string }>("version");

        if (!currentVersion || currentVersion.version !== DataCacheHelper.LOCAL_STORAGE_VERSION) {
            localStorageClient.clear(undefined);
            localStorageClient.save("version", { version: DataCacheHelper.LOCAL_STORAGE_VERSION });
        }
    }
}
