import { IEdfGrantTrackerConfiguration } from "../models/IEdfGrantTrackerConfiguration";

/**
 * Class to help with tangle explorer.
 */
export class TangleExplorerHelper {
    /**
     * Create a bundle link to tangle explorer and open if required.
     * @param configuration The configuration to get the explore link from.
     * @param bundleHash The hash of the bundle to display.
     * @param open Open the link in a new tab.
     * @returns The generated link.
     */
    public static bundle(configuration: IEdfGrantTrackerConfiguration, bundleHash: string, open: boolean): string {
        if (!configuration.tangleExplorer) {
            return "";
        } else {
            const url = configuration.tangleExplorer.bundle.replace(":bundleHash", bundleHash);
            if (open) {
                window.open(url, "_blank");
            }
            return url;
        }
    }

    /**
     * Create a transaction link to tangle explorer and open if required.
     * @param configuration The configuration to get the explore link from.
     * @param transactionHash The hash of the transaction to display.
     * @param open Open the link in a new tab.
     * @returns The generated link.
     */
    public static transaction(configuration: IEdfGrantTrackerConfiguration, transactionHash: string, open: boolean): string {
        if (!configuration.tangleExplorer) {
            return "";
        } else {
            const url = configuration.tangleExplorer.transaction.replace(":transactionHash", transactionHash);
            if (open) {
                window.open(url, "_blank");
            }
            return url;
        }
    }
}
