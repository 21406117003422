import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import bigInt from "big-integer";
import React, { Component } from "react";
import FundData from "./fundData";
import { DataCacheHelper } from "./helpers/dataCacheHelper";
import { GrantDonationHelper } from "./helpers/grantDonationHelper";
import { IEdfGrantTrackerConfiguration } from "./models/IEdfGrantTrackerConfiguration";
import { IGrantDonation } from "./models/IGrantDonation";
import { IStatus } from "./models/IStatus";
import StatusCard from "./statusCard";
import "./view.css";
import ViewCard from "./viewCard";

/**
 * Component to display a list of all grants/donations.
 */
class View extends Component<
    {
        configuration: IEdfGrantTrackerConfiguration;
    },
    {
        status: IStatus;
        grantDonations: IGrantDonation[];
        filteredGrantDonations: IGrantDonation[];
        filterGrant: string;
        sortBy: string;
        searchFor: string;
    }> {

    constructor(
        props: { configuration: IEdfGrantTrackerConfiguration },
        context: {}
    ) {
        super(props, context);
        this.state = {
            status: {
                showProgress: true,
                message: ""
            },
            grantDonations: [],
            filteredGrantDonations: [],
            filterGrant: "all",
            sortBy: "dateDescending",
            searchFor: ""
        };
    }

    /**
     * The component mounted.
     */
    public async componentDidMount(): Promise<void> {
        this.setState({
            status: {
                showProgress: true, message: "Loading Grants and Donations from The Tangle..."
            }
        });

        try {
            const grantDonations = await DataCacheHelper.loadCurrent<IGrantDonation>(
                this.props.configuration,
                this.props.configuration.edfGrantTrackerTableName);

            grantDonations.map(grantDonation => GrantDonationHelper.convertPayments(grantDonation));

            this.applyFiltersAndSort({ grantDonations });
        } catch (err) {
            this.setState({
                status: {
                    showProgress: false,
                    message: `Viewing items failed, please refresh the page to try again.
If this page continues to fail try again later.`
                }
            });
        }
    }

    /**
     * Render the component.
     * @returns The component content.
     */
    public render(): React.ReactNode {
        return (
            <Grid container spacing={3}>
                <FundData configuration={this.props.configuration} />

                {this.state.grantDonations.length > 0 && (
                    <Grid item xs={12}>
                        <Card className="filter-card">
                            <CardContent>
                                <form>
                                    <Grid container spacing={1} alignItems="flex-start">
                                        <Grid item>
                                            <FormControl className="filter-form-control">
                                                <InputLabel htmlFor="donations">Grants/Donations</InputLabel>
                                                <Select
                                                    value={this.state.filterGrant}
                                                    onChange={e =>
                                                        this.applyFiltersAndSort(
                                                            { filterGrant: e.target.value }
                                                        )}
                                                    inputProps={{
                                                        name: "donations",
                                                        id: "donations"
                                                    }}>
                                                    <MenuItem value="all">All</MenuItem>
                                                    <MenuItem value="grants">Grants Only</MenuItem>
                                                    <MenuItem value="donations">Donations Only</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl className="filter-form-control">
                                                <InputLabel htmlFor="sortBy">Sort By</InputLabel>
                                                <Select
                                                    value={this.state.sortBy}
                                                    onChange={e =>
                                                        this.applyFiltersAndSort(
                                                            { sortBy: e.target.value }
                                                        )}
                                                    name="sortBy"
                                                    id="sortBy">
                                                    <MenuItem value="dateDescending">Date Newest First
                                                    </MenuItem>
                                                    <MenuItem value="dateAscending">Date Oldest First
                                                    </MenuItem>
                                                    <MenuItem value="valueDescending">Value Descending
                                                    </MenuItem>
                                                    <MenuItem value="valueAscending">Value Ascending
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                value={this.state.searchFor}
                                                label="Search"
                                                placeholder="min 3 chars"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onChange={e =>
                                                    this.applyFiltersAndSort({ searchFor: e.target.value })}
                                                name="searchFor"
                                                id="searchFor"
                                                className="filter-form-control" />
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                )}

                <StatusCard status={this.state.status} />

                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {this.state.filteredGrantDonations.map((item, idx) =>
                            <Grid key={idx} item xs={12} sm={6} md={4}>
                                <ViewCard item={item} configuration={this.props.configuration} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    private applyFiltersAndSort(newState?: any): void {
        this.setState({
            status: {
                showProgress: true,
                message: "Filtering and Sorting Data"
            }
        });

        let filterGrant = this.state.filterGrant;
        let sortBy = this.state.sortBy;
        let searchFor = this.state.searchFor;
        let grantDonations = this.state.grantDonations;

        if (newState) {
            filterGrant = newState.filterGrant || filterGrant;
            sortBy = newState.sortBy || sortBy;
            searchFor = newState.searchFor === undefined ? searchFor : newState.searchFor;
            grantDonations = newState.grantDonations || grantDonations;
            this.setState(newState);
        }

        let filtered = grantDonations;

        if (filterGrant !== "all") {
            const flag = filterGrant === "grants";
            filtered = filtered.filter(gd => gd.grant === flag);
        }

        switch (sortBy) {
            case "valueAscending": filtered.sort((a, b) => bigInt(a.amnt).compare(bigInt(b.amnt))); break;
            case "valueDescending": filtered.sort((a, b) => bigInt(b.amnt).compare(bigInt(a.amnt))); break;
            case "dateAscending": filtered.sort((a, b) => a.ts - b.ts); break;
            default: filtered.sort((a, b) => b.ts - a.ts);
        }

        const searchLower = searchFor.trim().toLowerCase();
        if (searchLower.length >= 3) {
            filtered = filtered.filter(gd =>
                gd.title.toLowerCase().indexOf(searchLower) >= 0 ||
                gd.who.toLowerCase().indexOf(searchLower) >= 0 ||
                gd.desc.toLowerCase().indexOf(searchLower) >= 0);
        }

        let status = "";

        if (grantDonations.length === 0) {
            status = "There are no grants or donations to display.";
        } else if (filtered.length === 0) {
            status = "There are no grants or donations with the specified filters.";
        }

        this.setState({
            status: {
                message: status,
                showProgress: false
            },
            filteredGrantDonations: filtered
        });
    }
}

export default View;
