import { Transaction } from "@iota/core";
import { asTransactionObject } from "@iota/transaction-converter";
import { IotaHelper } from "../helpers/iotaHelper";
import { AmazonS3Client } from "./amazonS3Client";

/**
 * Class to communicate with tangle cache.
 */
export class TangleCacheClient {
    /* @internal */
    private readonly _amazonS3Client: AmazonS3Client;

    /* @internal */
    private readonly _cacheRoot: string;

    /**
     * Create a new instance of TangleCacheClient.
     * @param amazonS3Client The amazon client to communicate with.
     * @param cacheRoot The root path for the cache.
     */
    constructor(amazonS3Client: AmazonS3Client, cacheRoot: string) {
        this._amazonS3Client = amazonS3Client;
        this._cacheRoot = cacheRoot;
    }

    /**
     * Load transactions.
     * @param bundleHash The hash of the item.
     * @returns The item.
     */
    public async loadBundle<T>(bundleHash: string): Promise<T> {
        let ret: T;

        const bundleHashes: string[] = await this._amazonS3Client.getItem<string[]>(`${this._cacheRoot}/bundles/${bundleHash}.json`);

        if (bundleHashes) {
            const txObjects: Transaction[] = [];

            for (let i = 0; i < bundleHashes.length; i++) {
                const txTrytes = await this._amazonS3Client.getString(`${this._cacheRoot}/txs/${bundleHashes[i]}.trytes`);

                if (txTrytes) {
                    txObjects.push(asTransactionObject(txTrytes));
                }
            }

            const items = IotaHelper.extractBundles<T>(txObjects, true);

            if (items && items.length > 0) {
                ret = items[0];
            }

        }
        return ret;
    }
}
