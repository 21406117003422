import { NetworkClient } from "../network/networkClient";

/**
 * Class to communicate with amazon s3.
 */
export class AmazonS3Client {
    /* @internal */
    private readonly _networkClient: NetworkClient;

    /* @internal */
    private readonly _root: string;

    /**
     * Create a new instance of AmazonS3Client.
     * @param networkClient The network client to communicate with.
     * @param root The root for the s3 bucket.
     */
    constructor(networkClient: NetworkClient, root: string) {
        this._networkClient = networkClient;
        this._root = root.replace(/\/$/, "");
    }

    /**
     * Get the url for an item.
     * @param root The root for the bucket.
     * @param itemName The name of the item to get the url for.
     * @returns The item url.
     */
    public static getItemUrl(root: string, itemName: string): string {
        return `${root.replace(/\/$/, "")}/${itemName}`;
    }

    /**
     * Get the item from the network.
     * @param itemName The name of the item to get.
     * @returns The item.
     */
    public async getItem<T>(itemName: string): Promise<T> {
        return this._networkClient.requestJson<void, T>(
            `${this._root}/${itemName}`,
            "GET");
    }

    /**
     * Get the string from the network.
     * @param itemName The name of the item to get.
     * @returns The item.
     */
    public async getString(itemName: string): Promise<string> {
        return this._networkClient.request(
            `${this._root}/${itemName}`,
            "GET");
    }
}
