import { Transaction } from "@iota/core";
import { ISignedDataItem } from "../models/ISignedDataItem";
import { TrytesHelper } from "./trytesHelper";

/**
 * Helper functions for use with iota lib.
 */
export class IotaHelper {
    /**
     * Extra only the first objects from the bundles.
     * @param txObjects The transaction objects.
     * @param includeTangleInfo Include the tangle hashes in the object.
     * @returns The extracted objects.
     */
    public static extractBundles<T extends ISignedDataItem>(txObjects: Transaction[], includeTangleInfo: boolean): T[] {
        const bundles: { [hash: string]: Transaction[] } = {};

        txObjects.forEach(tx => {
            bundles[tx.bundle] = bundles[tx.bundle] || [];
            bundles[tx.bundle].push(tx);
        });

        const objs: T[] = [];
        Object.keys(bundles).forEach(hash => {
            // We only want one transaction from the bundle not reattachments

            // Sort all the transactions by timestamp so we can just get earliest
            bundles[hash].sort((a, b) => a.attachmentTimestamp - b.attachmentTimestamp);

            // Now look at the first entry and see how many parts it has
            const numParts = bundles[hash][0].lastIndex;

            // Grab that amount of entries
            const finalEntries = bundles[hash].slice(0, numParts + 1);

            // Sort each of the bundle transactions by index
            finalEntries.sort((a, b) => a.currentIndex - b.currentIndex);

            const obj = TrytesHelper.fromTrytes<ISignedDataItem>(finalEntries.map(a => a.signatureMessageFragment).join(""));

            if (obj && includeTangleInfo) {
                obj.bundleHash = finalEntries[0].bundle;
                obj.transactionHashes = finalEntries.map(t => t.hash);
            }

            objs.push(obj as T);
        });
        return objs;
    }
}
