import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ExploreIcon from "@material-ui/icons/Explore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import GrantIcon from "@material-ui/icons/PersonAdd";
import DonationIcon from "@material-ui/icons/Star";
import React, { Component } from "react";
import { CurrencyHelper } from "./helpers/currencyHelper";
import { DateHelper } from "./helpers/dateHelper";
import { TangleExplorerHelper } from "./helpers/tangleExplorerHelper";
import { UnitsHelper } from "./helpers/unitsHelper";
import { IEdfGrantTrackerConfiguration } from "./models/IEdfGrantTrackerConfiguration";
import { IGrantDonation } from "./models/IGrantDonation";
import { AmazonS3Client } from "./network/amazonS3Client";
import "./viewCard.css";

/**
 * Component to display a grant/donation.
 */
class ViewCard extends Component<
    {
        configuration: IEdfGrantTrackerConfiguration;
        item: IGrantDonation;
    },
    {
        anchorElement: HTMLElement;
    }> {

    constructor(
        props:
            {
                configuration: IEdfGrantTrackerConfiguration;
                item: IGrantDonation;
            },
        context: {
        }
    ) {
        super(props, context);
        this.state = {
            anchorElement: null
        };
    }

    /**
     * Render the component.
     * @returns The component content.
     */
    public render(): React.ReactNode {
        const hasMenu = this.props.item.url ||
            (this.props.item.transactionHashes && this.props.item.transactionHashes.length > 0);

        return (
            <Grow in={true}>
                <Card className={this.props.item.grant ? "card-grant" : "card-donation"}>
                    <CardHeader
                        avatar={<Avatar
                            aria-label={this.props.item.grant ? "Grant" : "Donation"}
                            className={this.props.item.grant ? "bg-grant" : "bg-donation"}>
                            {this.props.item.grant ? <GrantIcon /> : <DonationIcon />}
                        </Avatar>}
                        action={hasMenu && (<IconButton
                            aria-label="More"
                            aria-owns={this.state.anchorElement ? "more-menu" : null}
                            aria-haspopup="true"
                            onClick={(e) => this.toggleMenu(e.currentTarget)}>
                            <MoreVertIcon />
                        </IconButton>)}
                        title={this.props.item.title}
                        subheader={`${DateHelper.formatDate(this.props.item.ts)} - ${this.props.item.who}`}
                    />
                    <Menu
                        id="more-menu"
                        anchorEl={this.state.anchorElement}
                        open={Boolean(this.state.anchorElement)}
                        onClose={(e) => this.toggleMenu(null)}
                    >
                        {this.props.item.url && (
                            <MenuItem onClick={(e) => this.openUrl()}>
                                <ListItemIcon>
                                    <OpenInNewIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Visit Web Site" />
                            </MenuItem>
                        )}
                        {this.props.item.transactionHashes && this.props.item.transactionHashes.length > 0 && (
                            <MenuItem
                                onClick={e => this.openTangle()}>
                                <ListItemIcon>
                                    <ExploreIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Explore on Tangle" />
                            </MenuItem>
                        )}
                    </Menu>
                    <CardContent>
                        {this.props.item.desc.split("\n").map((l, i) =>
                            (<Typography key={i} variant="body1"
                                className="card-description-content">
                                {l}
                            </Typography>)
                        )}
                        <br />
                        <Table className="payment-table">
                            <TableBody>
                                {this.props.item.pymnts.map((payment, idx3) => (
                                    <TableRow key={idx3}>
                                        <TableCell padding="none">
                                            {idx3 === 0 && (
                                                <Typography variant="body1">
                                                    {this.props.item.grant ? "Grant" : "Donation"}
                                                    {this.props.item.pymnts.length > 1 ? "s" : ""}
                                                </Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {this.props.item.grant && (
                                                <Tooltip title={CurrencyHelper.formatUsdRaw(payment.usd)} placement="bottom-end">
                                                    <Typography variant="body1" className="grant" noWrap>
                                                        {CurrencyHelper.formatUsd(parseFloat(payment.usd))}
                                                    </Typography>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={payment.amnt} placement="bottom-end">
                                                <Typography variant="body1"
                                                    className={this.props.item.grant ? "grant" : "donation"}
                                                    noWrap>
                                                    {UnitsHelper.format(payment.amnt, 2)}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            {payment.txs.map((tx, idx2) =>
                                                (
                                                    <React.Fragment key={idx2}>
                                                        {idx2 > 0 && idx2 % 3 === 0 && (<br />)}
                                                        <Button
                                                            onClick={e => TangleExplorerHelper.transaction(this.props.configuration, tx, true)}
                                                            size="small"
                                                            color="primary"
                                                            className="button-xs">
                                                            <OpenInNewIcon />
                                                        </Button>
                                                    </React.Fragment>
                                                )
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                    {this.props.item.docs && this.props.item.docs.length > 0 && (
                        <div className="card-actions">
                            <Divider />
                            <CardActions>
                                <Grid container direction="column" justify="flex-end">
                                    <Grid container alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography variant="body1" color="primary">
                                                DOCUMENTS
                                            </Typography>
                                        </Grid>
                                        {this.props.item.docs.map((doc, idx2) => (
                                            <React.Fragment key={idx2}>
                                                <Grid item xs={10}>
                                                    <Typography variant="body1" className="document">
                                                        {doc.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} className="document-link">
                                                    <Button
                                                        onClick={e => this.openDocument(idx2)}
                                                        size="small"
                                                        color="primary"
                                                        className="button-xs">
                                                        <CloudDownloadIcon />
                                                    </Button>
                                                </Grid>
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </div>
                    )}
                </Card>
            </Grow>
        );
    }

    private toggleMenu(anchorElement: HTMLElement): void {
        this.setState({ anchorElement });
    }

    private openUrl(): void {
        this.setState({ anchorElement: null });
        window.open(this.props.item.url, "_blank");
    }

    private openTangle(): void {
        this.setState({ anchorElement: null });
        TangleExplorerHelper.transaction(this.props.configuration, this.props.item.transactionHashes[0], true);
    }

    private openDocument(docIdx: number): void {
        this.setState({ anchorElement: null });
        const doc = this.props.item.docs[docIdx];
        const docUrl = AmazonS3Client.getItemUrl(
            this.props.configuration.storageRoot,
            `${this.props.configuration.projectsPath}/${this.props.item.ecoId}/${doc.ts}_${doc.name}`);
        window.open(docUrl, "_blank");
    }
}

export default ViewCard;
