/**
 * Class to help with the date time.
 */
export class DateHelper {
    /**
     * Format the timestamp as a date.
     * @param timestamp The timestamp to format.
     * @returns The formatted date.
     */
    public static formatDate(timestamp: number): string {
        if (!timestamp) {
            return "";
        } else {
            const dt = new Date(timestamp);
            const monName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][dt.getMonth()];
            return `${DateHelper.padTwo(dt.getDate())}-${monName}-${dt.getFullYear()}`;
        }
    }

    /**
     * Format the timestamp as a file stamp.
     * @param timestamp The timestamp to format.
     * @returns The formatted date time.
     */
    public static formatFileStamp(timestamp: number): string {
        const dt = new Date(timestamp);
        return `${dt.getFullYear()}-${DateHelper.padTwo(dt.getMonth() + 1)}-${DateHelper.padTwo(dt.getDate())}-${DateHelper.padTwo(dt.getHours())}-${DateHelper.padTwo(dt.getMinutes())}`;
    }

    private static padTwo(val: number): string {
        return `00${val}`.slice(-2);
    }
}
