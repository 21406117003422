import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import "./fundData.css";
import { CurrencyHelper } from "./helpers/currencyHelper";
import { FundHelper } from "./helpers/fundHelper";
import { IEdfGrantTrackerConfiguration } from "./models/IEdfGrantTrackerConfiguration";
import { IEdfGrantTrackerFund } from "./models/IEdfGrantTrackerFund";

/**
 * Component to display the fund data.
 */
class FundData extends Component<
    {
        configuration: IEdfGrantTrackerConfiguration;
    },
    {
        fundData: IEdfGrantTrackerFund;
    }> {

    constructor(
        props: { configuration: IEdfGrantTrackerConfiguration },
        context: {}) {
        super(props, context);

        this.state = {
            fundData: undefined
        };
    }

    /**
     * The component mounted.
     */
    public async componentDidMount(): Promise<void> {
        try {
            const fundData = await FundHelper.load(this.props.configuration);

            this.setState({
                fundData
            });
        } catch (err) {
            // Dont show anything if failed
        }
    }

    /**
     * Render the component.
     * @returns The component content.
     */
    public render(): React.ReactNode {
        return !this.state.fundData || this.state.fundData.lastUpdated === 0 ? null : (
            <Grid item xs={12}>
                <Card className="fund-card">
                    <CardContent>
                        <Grid container alignItems="center">
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" noWrap className="fund-donation">
                                    Donations
                                </Typography>
                                <Tooltip title={this.state.fundData.donations} placement="bottom-start">
                                    <Typography variant="subtitle1" className="fund-donation" noWrap>
                                        {this.state.fundData.donationsFormatted}
                                    </Typography>
                                </Tooltip>
                                <Tooltip
                                    title={CurrencyHelper.formatUsdRaw(this.state.fundData.donationsUsd)}
                                    placement="bottom-start">
                                    <Typography variant="subtitle1" className="fund-donation" noWrap>
                                        {this.state.fundData.donationsUsdFormatted}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" noWrap className="fund-grant">
                                    Grants
                                </Typography>
                                <Tooltip
                                    title={this.state.fundData.grants}
                                    placement="bottom-start">
                                    <Typography variant="subtitle1" className="fund-grant" noWrap>
                                        {this.state.fundData.grantsFormatted}
                                    </Typography>
                                </Tooltip>
                                <Tooltip
                                    title={CurrencyHelper.formatUsdRaw(this.state.fundData.grantsUsd)}
                                    placement="bottom-start">
                                    <Typography variant="subtitle1" className="fund-grant" noWrap>
                                        {this.state.fundData.grantsUsdFormatted}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="fund-available" noWrap>
                                    Available
                                </Typography>
                                <Tooltip
                                    title={this.state.fundData.available}
                                    placement="bottom-start">
                                    <Typography variant="subtitle1" className="fund-available" noWrap>
                                        {this.state.fundData.availableFormatted}
                                    </Typography>
                                </Tooltip>
                                <Tooltip
                                    title={CurrencyHelper.formatUsdRaw(this.state.fundData.availableUsd)}
                                    placement="bottom-start">
                                    <Typography variant="subtitle1" className="fund-available" noWrap>
                                        {this.state.fundData.availableUsdFormatted}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            {this.props.configuration.donationsEmail && (
                                <Grid item xs={12}>
                                    <Typography className="donations-email" variant="body1">
                                        <br />
                                        If you would like to make a donation to the Ecosystem Development Fund,
                                        please first contact us at
                                        <Button size="small"
                                        href={`mailto:${this.props.configuration.donationsEmail}`}>{this.props.configuration.donationsEmail}</Button>
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default FundData;
