import { IGrantDonation } from "../models/IGrantDonation";

/**
 * Class to help with grant/donation data.
 */
export class GrantDonationHelper {
    /**
     * Convert payments from the old single type to the new multiple version.
     * @param item The item to convert the payments for.
     */
    public static convertPayments(item: IGrantDonation): void {
        if (item.amnt !== undefined) {
            // This is old format with single payment
            // so just convert it to array
            item.pymnts = [ {
                amnt: item.amnt,
                usd: item.usd,
                txs: item.txs
            } ];

            item.amnt = undefined;
            item.usd = undefined;
            item.txs = undefined;
        }
    }
}
