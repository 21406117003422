import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { IStatus } from "./models/IStatus";
import "./statusCard.css";

/**
 * Component to display status information.
 */
class StatusCard extends Component<
    {
        status: IStatus;
    },
    {
    }> {

    /**
     * Render the component.
     * @returns The component content.
     */
    public render(): React.ReactNode {
        return (this.props.status.message.length > 0 || this.props.status.showProgress) && (
            <Grid item xs={12}>
                <Card className="status-card">
                    <CardContent>
                        {this.props.status.message && this.props.status.message.split("\n").map((l, i) => (
                            <Typography key={i} variant="subtitle1" paragraph>
                                {l}
                            </Typography>
                        ))}
                        {this.props.status.showProgress && (
                            <LinearProgress variant="query" />
                        )}
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default StatusCard;
