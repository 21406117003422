import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExploreIcon from "@material-ui/icons/Explore";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import React, { Component } from "react";
import "./auditCard.css";
import AuditDetails from "./auditDetails";
import { DateHelper } from "./helpers/dateHelper";
import { TangleExplorerHelper } from "./helpers/tangleExplorerHelper";
import { IAuditOperation } from "./models/IAuditOperation";
import { IDataTableIndex } from "./models/IDataTableIndex";
import { IEdfGrantTrackerConfiguration } from "./models/IEdfGrantTrackerConfiguration";
import { IGrantDonation } from "./models/IGrantDonation";

/**
 * Component to display a list of audit entries.
 */
class AuditCard extends Component<
    {
        configuration: IEdfGrantTrackerConfiguration;
        item: { hash: string; index: IDataTableIndex; auditOperation?: IAuditOperation };
        grantDonation1: IGrantDonation;
        grantDonation2: IGrantDonation;
    },
    {
        expanded: boolean;
    }> {

    constructor(
        props:
            {
                configuration: IEdfGrantTrackerConfiguration;
                item: { hash: string; index: IDataTableIndex; auditOperation?: IAuditOperation };
                grantDonation1: IGrantDonation;
                grantDonation2: IGrantDonation;
            },
        context: {}
    ) {
        super(props, context);
        this.state = {
            expanded: false
        };
    }

    /**
     * Render the component.
     * @returns The component content.
     */
    public render(): React.ReactNode {
        const operation = this.props.item.auditOperation ? this.props.item.auditOperation.operation : "";
        const operationL = operation.toLowerCase();

        return (
            <Grow in={true}>
                <Card className={`audit-card card-${operationL}`}>
                    <CardHeader
                        avatar={<Avatar
                            aria-label={operation}
                            className={`bg-${operationL}`}>
                            {operationL === "add" ? <AddIcon />
                            : operationL === "update" ? <EditIcon />
                            : operationL === "delete" ? <DeleteIcon /> : <HourglassEmptyIcon />}
                        </Avatar>}
                        title={operation}
                        subheader={`${DateHelper.formatDate(this.props.item.index.ts)}`}
                    />
                    <CardContent className="audit-card-content">
                        <Grid container alignItems="flex-start">
                            <Grid item xs={12}>
                                <Typography variant="body1" color="primary">
                                    Index
                                </Typography>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography variant="body1" paragraph>
                                    {this.props.item.hash}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    onClick={e => TangleExplorerHelper.bundle(this.props.configuration, this.props.item.hash, true)}
                                    size="small"
                                    color="primary"
                                    className="button-xs">
                                    <ExploreIcon />
                                </Button>
                            </Grid>
                            {this.props.item.auditOperation && (
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" color="primary">
                                            {this.props.item.auditOperation.hash2 ? `From` : `Hash`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography variant="body1" paragraph>
                                            {this.props.item.auditOperation.hash1}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Button
                                            onClick={e => TangleExplorerHelper.bundle(this.props.configuration, this.props.item.auditOperation.hash1, true)}
                                            size="small"
                                            color="primary"
                                            className="button-xs">
                                            <ExploreIcon />
                                        </Button>
                                    </Grid>
                                    {this.props.item.auditOperation.hash2 && (
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" color="primary">
                                                    To
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography variant="body1" paragraph>
                                                    {this.props.item.auditOperation.hash2}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Button
                                                    onClick={e => TangleExplorerHelper.bundle(this.props.configuration, this.props.item.auditOperation.hash2, true)}
                                                    size="small"
                                                    color="primary"
                                                    className="button-xs">
                                                    <ExploreIcon />
                                                </Button>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </Grid>
                    </CardContent>
                    {this.props.item.auditOperation && (
                        <div className="card-actions">
                            <Divider />
                            <CardActions className="card-actions-small">
                                <Grid container direction="column" justify="flex-end" alignItems="flex-end">
                                    <Grid item>
                                        <Button onClick={(e) => this.toggleExpand()}>
                                            {this.state.expanded ? "Less Details" : "More Details"}
                                            &nbsp;
                                            {this.state.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                                <AuditDetails
                                    operation={this.props.item.auditOperation.operation}
                                    grantDonation1={this.props.grantDonation1}
                                    grantDonation2={this.props.grantDonation2}
                                />
                            </Collapse>
                        </div>
                    )}
                </Card>
            </Grow>
        );
    }

    private toggleExpand(): void {
        this.setState({ expanded: !this.state.expanded });
    }
}

export default AuditCard;
