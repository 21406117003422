/**
 * Class to communicate with network.
 */
export class NetworkClient {
    /**
     * Perform a request asynchronously.
     * @param uri A path to send the request.
     * @param method The method to send the data with.
     * @param data The data to send.
     * @param headers Headers to send with the request.
     * @returns Promise which resolves to the object returned or rejects with error.
     */
    public async request(uri: string, method: string, data?: string, headers?: { [header: string]: string }): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const req = new XMLHttpRequest();

            req.ontimeout = () => {
                reject(new Error(`Failed ${method} request, timed out
                    endPoint: ${uri}
                    errorResponseCode: ${req.status}
                    errorResponse: ${req.responseText || req.statusText}`
                ));
            };

            req.onerror = (err) => {
                reject(new Error(`Failed ${method} request
                    endPoint: ${uri}
                    errorResponseCode: ${req.status}
                    errorResponse: ${req.responseText || req.statusText}`
                ));
            };

            req.onload = () => {
                if (req.status === 200) {
                    resolve(req.responseText);
                } else {
                    reject(new Error(`Failed ${method} request, timed out
                        endPoint: ${uri}
                        errorResponseCode: ${req.status}
                        errorResponse: ${req.responseText || req.statusText}`
                    ));
                }
            };

            req.open(method, uri, true);

            for (const key in headers) {
                req.setRequestHeader(key, headers[key]);
            }

            req.send(data);
        });
    }

    /**
     * Perform a request asynchronously.
     * @param uri A path to send the request.
     * @param method The method to send the data with.
     * @param data The data to send.
     * @param headers Headers to send with the request.
     * @returns Promise which resolves to the object returned or rejects with error.
     */
    public async requestJson<T, U>(uri: string, method: string, data?: T, headers?: { [header: string]: string }): Promise<U> {
        const localHeaders = headers || {};
        localHeaders["Content-Type"] = "application/json";

        return this.request(uri, method, data ? JSON.stringify(data) : undefined, headers)
            .then((responseData) => {
                try {
                    const response = JSON.parse(responseData);
                    return response as U;
                } catch (err) {
                    throw(new Error(`Failed ${method} request, unable to parse response
                        endPoint: ${uri}
                        response: ${responseData}`
                    ));
                }
            });
    }
}
