import { IEdfGrantTrackerConfiguration } from "../models/IEdfGrantTrackerConfiguration";
import { IEdfGrantTrackerFund } from "../models/IEdfGrantTrackerFund";
import { ConfigHelper } from "./configHelper";

/**
 * Class to help with the fund data.
 */
export class FundHelper {
    /* @internal */
    private static fundData: IEdfGrantTrackerFund;

    /**
     * Load the fund data.
     * @param configuration The configuration to use for loading.
     * @returns The fund data.
     */
    public static async load(configuration: IEdfGrantTrackerConfiguration): Promise<IEdfGrantTrackerFund> {
        if (!FundHelper.fundData) {
            const amazonS3Client = ConfigHelper.createAmazonS3Client(configuration);

            try {
                FundHelper.fundData = await amazonS3Client.getItem<IEdfGrantTrackerFund>(`${configuration.fundName}.json`);
            } catch (err) {
                // Try loading locally instead
            }

            const localStorageClient = ConfigHelper.createLocalStorageClient();
            if (FundHelper.fundData) {
                localStorageClient.save<IEdfGrantTrackerFund>(configuration.fundName, FundHelper.fundData);
            } else {
                FundHelper.fundData = localStorageClient.load<IEdfGrantTrackerFund>(configuration.fundName);
            }
        }

        return FundHelper.fundData;
    }
}
