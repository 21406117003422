import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import EdfClientRoot from "./edfGrantTrackerClient/root";
import "./index.css";

/**
 * Render the root.
 */
ReactDOM.render(
    <BrowserRouter>
        <EdfClientRoot />
    </BrowserRouter>,
    document.getElementById("root")
);
