/**
 * Helper functions for use with text.
 */
export class TextHelper {
    /**
     * Encode Non ASCII characters to escaped characters.
     * @param value The value to encode.
     * @returns The encoded value.
     */
    public static encodeNonASCII(value: string): string {
        return value ? value.replace(/[\u007F-\uFFFF]/g, (chr) => `\\u${(`0000${chr.charCodeAt(0).toString(16)}`).substr(-4)}`) : undefined;
    }

    /**
     * Decode escaped Non ASCII characters.
     * @param value The value to decode.
     * @returns The decoded value.
     */
    public static decodeNonASCII(value: string): string {
        return value ? value.replace(/\\u([\d\w]{4})/gi, (match, grp) => String.fromCharCode(parseInt(grp, 16))) : undefined;
    }

    /**
     * Convert the text to title case.
     * @param value The text to convert to title case.
     * @returns The title cases text.
     */
    public static titleCase(value: string): string {
        if (!value) {
            return "";
        }
        return value
            .replace(/_/g, " ")
            .replace(/([A-Z])/g, " $1")
            .trim()
            .split(" ")
            .map((word) => `${word[0].toUpperCase()}${word.substr(1)}`)
            .join(" ");
    }
}
