import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Component } from "react";
import "./auditDetails.css";
import { TextHelper } from "./helpers/textHelper";
import { AuditOperation } from "./models/auditOperation";
import { IGrantDonation } from "./models/IGrantDonation";

/**
 * Component to display the details of an audit item.
 */
class AuditDetails extends Component<
    {
        operation: AuditOperation;
        grantDonation1: IGrantDonation;
        grantDonation2: IGrantDonation;
    },
    {
        keys: string[];
        valuesFrom: string[];
        valuesTo: string[];
    }> {

    constructor(
        props: {
            operation: AuditOperation;
            grantDonation1: IGrantDonation;
            grantDonation2: IGrantDonation;
        },
        context: {}
    ) {
        super(props, context);
        this.state = {
            keys: [],
            valuesFrom: [],
            valuesTo: []
        };
    }

    /**
     * The component mounted.
     */
    public async componentDidMount(): Promise<void> {
        const gd1: any = this.props.grantDonation1 || {};
        const gd2: any = this.props.grantDonation2 || {};

        delete gd1.bundleHash;
        delete gd2.bundleHash;

        const gdTotal = {...gd1, ...gd2};
        const gdTotalFlat = this.flattenObject(gdTotal);
        const gdFlat1 = this.flattenObject(gd1);
        const gdFlat2 = this.flattenObject(gd2);

        const keys = Object.keys(gdTotalFlat);

        const values1 = this.props.grantDonation1 ? keys.map(k => gdFlat1[k] === undefined ? "" : gdFlat1[k].toString()) : [];
        const values2 = this.props.grantDonation2 ? keys.map(k => gdFlat2[k] === undefined ? "" : gdFlat2[k].toString()) : [];

        this.setState({
            keys,
            valuesFrom: values1,
            valuesTo: values2
        });
    }

    /**
     * Render the component.
     * @returns The component content.
     */
    public render(): React.ReactNode {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Table className="audit-details-table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="cell-small-padding">
                                    Key
                                </TableCell>
                                {this.state.valuesTo.length > 0 && (
                                    <TableCell className="cell-small-padding">
                                        New Value
                                    </TableCell>
                                )}
                                <TableCell className="cell-small-padding">
                                    {this.state.valuesTo.length > 0 ? "Old Value" : "Value"}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.keys.map((key, i) => (
                                <TableRow key={i} className="audit-details-table">
                                    <TableCell className="cell-min-width cell-small-padding">
                                        {this.formatKey(key)}
                                    </TableCell>
                                    {this.state.valuesTo.length > 0 && (
                                        <TableCell
                                            className={`cell-small-padding ${this.state.valuesFrom[i] === this.state.valuesTo[i]
                                                ? "" : "change-highlight-new"}`}>
                                            {this.state.valuesTo[i].split("\n").map((item2, idx2) => (
                                                <span key={idx2}>{item2}<br /></span>
                                            ))}
                                        </TableCell>
                                    )}
                                    <TableCell className={`cell-small-padding ${this.state.valuesFrom[i] === this.state.valuesTo[i]
                                                ? "" : (this.props.operation !== "ADD" ? "change-highlight-old" : "change-highlight-new")}`}>
                                        {this.state.valuesFrom[i].split("\n").map((item, idx1) => (
                                            <span key={idx1}>{item}<br /></span>
                                        ))}
                                    </TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        );
    }

    private formatKey(key: string): string {
        return TextHelper.titleCase(
                key.replace(/\./g, " ")
                   .replace(/(\d+)/g, (a, n) => (parseInt(n, 10) + 1).toString())
        );
    }

    private flattenObject(ob: any): any {
        const toReturn = {};

        for (const key in ob) {
            if (!ob.hasOwnProperty(key)) {
                continue;
            }
            if ((typeof ob[key]) === "object") {
                const flatObject = this.flattenObject(ob[key]);
                for (const key2 in flatObject) {
                    if (!flatObject.hasOwnProperty(key2)) {
                        continue;
                    }
                    toReturn[`${key}.${key2}`] = flatObject[key2];
                }
            } else {
                toReturn[key] = ob[key];
            }
        }
        return toReturn;
    }
}

export default AuditDetails;
